<template>
  <div id="app">
    <h2>视频会议</h2>
    <div>
      <input v-model="roomId" placeholder="请输入房间号" />
      <button @click="createRoom">创建房间</button>
      <button @click="joinRoom">加入房间</button>
    </div>
    <div v-if="connected">
      <video ref="localVideo" autoplay muted></video>
      <video ref="remoteVideo" autoplay></video>
      <button @click="startCall">开始呼叫</button>
    </div>
  </div>
</template>

<script>
import io from 'socket.io-client';

export default {
  data() {
    return {
      socket: null,
      localStream: null,
      remoteStream: null,
      peerConnection: null,
      roomId: '', // 房间号
      connected: false,
      config: {
        iceServers: [{ urls: 'stun:stun.l.google.com:19302' }]
      }
    };
  },
  methods: {
    // 创建房间
    createRoom() {
      if (!this.roomId) {
        alert('请输入房间号');
        return;
      }
      this.socket.emit('create-room', { room_id: this.roomId });
      this.socket.on('room-created', (data) => {
        this.connected = true;
        console.log(`房间已创建: ${data.room_id}`);
      });
      this.socket.on('error', (data) => {
        console.log(data);
        
        alert(data.message);
      });
    },

    // 加入房间
    joinRoom() {
      if (!this.roomId) {
        alert('请输入房间号');
        return;
      }
      this.socket.emit('join-room', { room_id: this.roomId });
      this.socket.on('room-joined', (data) => {
        this.connected = true;
        console.log(`已加入房间: ${data.room_id}`);
      });
      this.socket.on('error', (data) => {
        alert(data.message);
      });
    },

    async startCall() {
      try {
        // 检查 navigator.mediaDevices 是否可用
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
          throw new Error('getUserMedia is not supported in this browser.');
        }

        // 获取用户的媒体流（视频和音频）
        this.localStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        this.$refs.localVideo.srcObject = this.localStream;

        this.peerConnection = new RTCPeerConnection(this.config);

        // 添加本地流到 RTCPeerConnection
        this.localStream.getTracks().forEach(track => {
          this.peerConnection.addTrack(track, this.localStream);
        });

        // 监听远程流
        this.peerConnection.ontrack = event => {
          this.remoteStream = event.streams[0];
          this.$refs.remoteVideo.srcObject = this.remoteStream;
        };

        // ICE Candidate
        this.peerConnection.onicecandidate = event => {
          if (event.candidate) {
            this.socket.emit('ice-candidate', {
              candidate: event.candidate,
              room_id: this.roomId
            });
          }
        };

        this.socket.on('offer', async data => {
          if (data.room_id === this.roomId) {
            await this.peerConnection.setRemoteDescription(new RTCSessionDescription(data.offer));
            const answer = await this.peerConnection.createAnswer();
            await this.peerConnection.setLocalDescription(answer);
            this.socket.emit('answer', {
              answer,
              room_id: this.roomId
            });
          }
        });

        this.socket.on('answer', data => {
          if (data.room_id === this.roomId) {
            this.peerConnection.setRemoteDescription(new RTCSessionDescription(data.answer));
          }
        });

        this.socket.on('ice-candidate', data => {
          if (data.room_id === this.roomId) {
            this.peerConnection.addIceCandidate(new RTCIceCandidate(data.candidate));
          }
        });

        const offer = await this.peerConnection.createOffer();
        await this.peerConnection.setLocalDescription(offer);
        console.log(this.roomId);
        
        this.socket.emit('offer', { offer, room_id: this.roomId });
      } catch (error) {
        console.error('Error accessing media devices.', error);
        alert('无法访问媒体设备: ' + error.message);
      }
    }
  },
  mounted() {
    this.socket = io('192.168.3.187:5000');
  }
};
</script>

<style scoped>
video {
  width: 300px;
  margin: 10px;
}
</style>
